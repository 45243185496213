<template>
  <div>
    <h4 class="text-center">
      Пункт 1
    </h4>
    <template>
      <div class="w-100 mt-3">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          responsive
        >
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ data.item.id }}</span>
          </template>
          <template #cell(status)="data">
            <b-button
              variant="primary"
              size="sm"
              @click="handleEdit(data.item.id)"
            >
              <b-icon
                icon="edit"
                class="text-white"
              />
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <h3>История Раздела</h3>

    <div class="text-center">
      <b-button variant="success">
        Показать еще<b-icon
          class="btn"
          icon="chevron-down"
        /></b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PunktOne',
  data() {
    return {
      methods: {
        handleEdit(id) {
          this.$router.push({ name: 'home', params: { id } })
        },
      },
      items: [
        {
          name: 'Образовать Агентство по развитию\n'
              + 'медико-социальных услуг Республики\n'
              + 'Узбекистан (далее — Агентство) и его\n'
              + 'территориальные подразделения на\n'
              + 'базе Агентства медико-социальных\n'
              + 'услуг при Министерстве\n'
              + 'здравоохранения Республики\n'
              + 'Узбекистан и его территориальных\n'
              + 'подразделений',
          adder: 'Министерство здравоохранения\n'
              + 'Karimov Bahodir ',
          date: '09.06.2021',
          editor: 'Министерство здравоохранения',
          status: 1,
        },
      ],

    }
  },
}
</script>

<style scoped>

</style>
